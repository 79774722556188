export const API_TESTING_URL = "https://asgard-testing.fly.dev";
export const ODIN_FEE_CALC_URL =
  "https://docs.google.com/spreadsheets/d/10bffnVYuLpkP1hPZsyyykG80LwpWszrH7t9EJs8YYjo/edit#gid=1102842550";
export const ODIN_TWITTER_HANDLE = "@joinodin";
export const KYC_AML_POLICY_URL =
  "https://help.joinodin.com/hc/en-gb/articles/25651184351121-KYC-AML-checks";

export const EUR_FX_RATE = 1.2;
export const USD_FX_RATE = 1.28;

// Prevent investments in the platform on production, see
// @see https://linear.app/aesir/issue/ENG-2170/fca-changes-12-gracefully-pause-client-money-operations-until-aa
const webAddress = process.env["NEXT_PUBLIC_WEB_ADDRESS"];
const isProduction =
  Boolean(webAddress) &&
  Boolean(webAddress?.includes("https://app.joinodin.com"));
export const NEW_INVESTMENT_SHUTDOWN = isProduction;
