import { HStack, HstackProps } from "@@panda/jsx";
import { useContext } from "react";

import { DialogContext } from "../context";

interface Props extends HstackProps {
  testId?: string;
}

export function BaseFooter({
  children,
  testId = "modal-footer",
  ...rest
}: Props) {
  const modalContext = useContext(DialogContext);

  return modalContext.isLargeScreen ? (
    <HStack
      justifyContent="flex-end"
      pt="xl"
      data-testid={testId}
      gap="m"
      {...rest}
    >
      {children}
    </HStack>
  ) : (
    <HStack
      justifyContent="space-between"
      data-testid={testId}
      w="full"
      pt="xl"
      alignItems="flex-end"
      gap="m"
      {...rest}
    >
      {children}
    </HStack>
  );
}
