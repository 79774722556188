/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * An Odin platform subscription tier used to create deals with specific
pricing.

NOTE: The API will auto-uppercase any values before processing.

 */
export type SubscriptionTier =
  (typeof SubscriptionTier)[keyof typeof SubscriptionTier];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionTier = {
  BASE_2023: "BASE_2023",
  CORE_2023: "CORE_2023",
  CORE_2023_MONTHLY: "CORE_2023_MONTHLY",
  HEADLINER_2023: "HEADLINER_2023",
  HEADLINER_2023_MONTHLY: "HEADLINER_2023_MONTHLY",
} as const;
