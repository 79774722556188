import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  type UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { request } from "graphql-request";

import { TadaDocumentNode } from "./gql";

export const gqlRequest = request;

export type GqlError = Array<{
  message?: string;
  path?: Array<string>;
  extensions?: {
    correlation_id: string;
  };
}>;

export const GQL_URL =
  process.env["NODE_ENV"] === "test"
    ? `${process.env["API_ADDRESS"]}/asgard/graphql`
    : `/asgard/graphql`;

export function useGraphql<TResult, TVariables>(
  document: TadaDocumentNode<TResult, TVariables>,
  opts?: {
    variables?: TVariables;
    options?: Omit<
      UseQueryOptions<TResult, GqlError, TResult, [string, TVariables]>,
      "queryKey" | "quertFn"
    >;
  }
): UseQueryResult<TResult, GqlError> {
  return useQuery({
    // @ts-expect-error not sure about variables type error
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- https://the-guild.dev/graphql/codegen/docs/guides/react-vue#appendix-i-react-query-with-a-custom-fetcher-setup
    queryKey: [(document.definitions[0] as any).name.value, opts?.variables],
    queryFn: async () => {
      try {
        return await request(GQL_URL, document, opts?.variables ?? {});
      } catch (e) {
        // @ts-expect-error accessing safely
        throw e?.response?.errors ?? null;
      }
    },
    ...opts?.options,
  });
}

export function useGraphqlMutation<TResult, TVariables>(
  document: TadaDocumentNode<TResult, TVariables>,
  opts?: {
    options?: UseMutationOptions<TResult, GqlError, TVariables, unknown>;
  }
): UseMutationResult<TResult, GqlError, TVariables, unknown> {
  return useMutation({
    mutationFn: async (vars) => {
      try {
        return await request(GQL_URL, document, vars ?? {});
      } catch (e) {
        // @ts-expect-error accessing safely
        throw e?.response?.errors ?? null;
      }
    },
    ...opts?.options,
  });
}
