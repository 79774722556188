import { Flex, HStack, VStack } from "@@panda/jsx";
import { Card } from "components/display/Card";
import { Radio } from "components/display/Radio";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { Button } from "components/main/Button";
import { StepWrap } from "screens/onboarding/components/StepWrap";
import { CategoryType } from "screens/onboarding/steps/CategorisationStep/utils";

import { useCategoryDetail } from "./useCategoryDetail";
import { Statement } from "./utils";

interface Props {
  type: CategoryType;
  title: string;
  desc: string;
  statements: Statement[];
}

export function CategoryDetail({ type, title, statements, desc }: Props) {
  const {
    isLgScreenUp,
    isSubmitting,
    activeStatement,
    selectedIndex,
    onSubmit,
    handleSelectStatement,
    handleSetActiveStatement,
    handleResetStatements,
    handleGoToCategories,
  } = useCategoryDetail({
    statements,
    type,
  });

  return (
    <StepWrap>
      {activeStatement ? (
        <VStack gap="m" mb="m" w="full">
          <Typography
            as="h2"
            variant="heading.2"
            textAlign="center"
            width="full"
          >
            About your answer
          </Typography>

          <Typography
            as="p"
            variant="body"
            color="grey.gunsmoke"
            textAlign="center"
          >
            {activeStatement.text}
            <br />
            <br />
            {activeStatement.subText}
          </Typography>

          <Form
            config={{ schema: activeStatement.schema }}
            onSubmit={onSubmit}
            w="full"
          >
            {activeStatement.field}
            <Flex w="full" alignItems="flex-end" mt="2rem" gap="m">
              <Button
                variant="secondary"
                onClick={handleResetStatements}
                w="full"
              >
                Back
              </Button>

              <Form.Submit w="full" isLoading={isSubmitting}>
                Continue
              </Form.Submit>
            </Flex>
          </Form>
        </VStack>
      ) : (
        <VStack gap="l">
          <VStack gap="m">
            <Typography
              as="h2"
              variant="heading.2"
              textAlign="center"
              width="full"
            >
              {title}
            </Typography>
            <Typography
              as="p"
              variant="body"
              color="grey.gunsmoke"
              maxW="sm"
              textAlign="center"
            >
              {desc}
            </Typography>
          </VStack>
          <VStack gap="m" alignItems="flex-start">
            {statements.map((statement, i) => (
              <Card
                key={statement.question}
                onClick={() => handleSelectStatement(i)}
                role="radio"
                cursor="pointer"
                withBorder
              >
                <HStack justifyContent="flex-start" gap="m">
                  <Radio
                    name={statement.question}
                    checked={selectedIndex === i}
                    size="md"
                    data-testid={`statement-${i + 1}`}
                    value={statement.question}
                    hideLabel
                  />

                  <VStack alignItems="flex-start">
                    <Typography as="p" variant="body" textAlign="left">
                      {statement.text}
                    </Typography>
                    {statement.subText && isLgScreenUp ? (
                      <Typography
                        as="p"
                        variant="body"
                        color="grey.gunsmoke"
                        fontStyle="italic"
                        textAlign="left"
                      >
                        {statement.subText}
                      </Typography>
                    ) : null}
                  </VStack>
                </HStack>
              </Card>
            ))}
          </VStack>

          <Flex w="full" alignItems="flex-end" mt="s" gap="m">
            <Button variant="secondary" w="full" onClick={handleGoToCategories}>
              Back
            </Button>
            <Button
              onClick={handleSetActiveStatement}
              isDisabled={selectedIndex === undefined}
              w="full"
            >
              Continue
            </Button>
          </Flex>
        </VStack>
      )}
    </StepWrap>
  );
}
