import { css } from "@@panda/css";
import { Flex, Stack, VStack } from "@@panda/jsx";
import { Divider } from "components/display/Divider";
import { Typography } from "components/display/Typography/Typography";
import { CopyText } from "components/interaction/CopyText/CopyText";

import { Link } from "../../interaction/Link";

type Props = {
  fullError?: unknown;
  userMsg?: string;
  title?: string;
  correlationId?: string;
};

export function ErrorBanner({
  fullError = "",
  userMsg = "",
  correlationId,
  title = "Something went wrong",
}: Props) {
  // eslint-disable-next-line no-console
  console.error(fullError);

  const titleSize = "xl";

  return (
    <Flex justifyContent="center" alignItems="center" padding="2">
      <Stack
        padding="2xl"
        borderRadius="2xl"
        bgColor="grey.woodsmoke"
        gap="m"
        maxW="37.5rem"
        marginX="auto"
      >
        {title ? (
          <VStack gap="m" alignItems="flex-start">
            <Typography
              variant="heading.3"
              fontSize={titleSize}
              mr="4"
              whiteSpace="nowrap"
            >
              {title}
            </Typography>
          </VStack>
        ) : null}
        <div>
          {userMsg ? (
            <Typography variant="body" mb="m">
              {userMsg}
            </Typography>
          ) : null}

          {correlationId ? (
            <Typography variant="body">
              <>
                Unique error code for tracking this problem:{" "}
                <CopyText
                  className={css({ p: "0" })}
                  label={
                    <code
                      className={css({
                        bgColor: "grey.bunker",
                        p: "s",
                        borderRadius: "md",
                        color: "white",
                      })}
                    >
                      {correlationId}
                    </code>
                  }
                  text={correlationId}
                  toastLabel="Error code"
                ></CopyText>
              </>
            </Typography>
          ) : null}
        </div>
        <Divider />
        <Typography variant="body">
          If this error persists, please contact support at{" "}
          <Link
            href={`mailto:hello@joinodin.com?subject=Error%20code%20${correlationId}`}
          >
            hello@joinodin.com
          </Link>{" "}
          and include the above error code.
        </Typography>
      </Stack>
    </Flex>
  );
}
