/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * A discriminant to determine which price plan variant to use.
 */
export type PricePlanVariant =
  (typeof PricePlanVariant)[keyof typeof PricePlanVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricePlanVariant = {
  floor_cap: "floor_cap",
  base_plus_variable: "base_plus_variable",
} as const;
