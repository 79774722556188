/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * An Odin platform pricing plan used to calculate deal fees.
 */
export type PricePlanName = (typeof PricePlanName)[keyof typeof PricePlanName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricePlanName = {
  founder_2020: "founder_2020",
  entry_2020: "entry_2020",
  standard_2020: "standard_2020",
  founder_2022: "founder_2022",
  standard_2022: "standard_2022",
  branded_2022: "branded_2022",
  founder_2023: "founder_2023",
  standard_2023: "standard_2023",
  branded_2023: "branded_2023",
  founder_2023_07: "founder_2023_07",
  founder_2023_09: "founder_2023_09",
  base_2023: "base_2023",
  core_2023: "core_2023",
  core_2023_monthly: "core_2023_monthly",
  headliner_2023: "headliner_2023",
  headliner_2023_monthly: "headliner_2023_monthly",
} as const;
