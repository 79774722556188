import { VStack, styled } from "@@panda/jsx";
import { zendesk } from "services/zendesk";

import { Link } from "../../interaction/Link";
import { getLayoutProps } from "../Layout/utils";
import { useFooter } from "./useFooter";

const mailTo = "mailto:hello@joinodin.com";
const helpCenterURL = "https://help.joinodin.com/";

export function Footer() {
  const { apiVersion, webVersion } = useFooter();

  return (
    <styled.footer {...getLayoutProps()} data-print="false">
      <VStack gap="4" alignItems="flex-start">
        <styled.p color="grey.gunsmoke" fontSize="12px">
          If you need help, we&apos;re available on&nbsp;
          <styled.button
            color="brand.krilin"
            _hover={{ color: "brand.yamcha" }}
            fontWeight={600}
            onClick={() => zendesk.toggleChatWindow()}
          >
            Chat,
          </styled.button>
          <Link href={mailTo} isExternal>
            {" "}
            Email
          </Link>
          &nbsp;or you can check out our{" "}
          <Link href={helpCenterURL} isExternal>
            Help center
          </Link>
          .
        </styled.p>

        <styled.p
          fontSize="xs"
          color="grey.gunsmoke"
          paddingRight={{ base: "16", lg: "0" }}
        >
          Join Odin Limited (trading as &lsquo;Odin&rsquo;) is not regulated by
          the UK Financial Conduct Authority. Our platform provides an
          integrated stack of administrative tools to enable fundraising
          companies, venture firms and angel syndicates to administer their
          deals seamlessly, but we do not take part in the arrangement of such
          deals, nor do we provide advice to any individuals participating in
          such deals.
          <br />
          <br />
          Furthermore, access to our platform is restricted only to those
          individuals who are able to first truthfully evidence that they are
          persons eligible to receive exempted financial promotions in respect
          of private investment opportunities in compliance with the Financial
          Services and Markets Act 2000 (Financial Promotion) Order 2005 (SI
          2005/1529).
        </styled.p>

        <styled.p fontSize="xs" color="grey.gunsmoke" paddingRight="16">
          For further information, please contact{" "}
          <Link href="mailto:hello@joinodin.com" isExternal>
            hello@joinodin.com
          </Link>
          .
        </styled.p>

        <styled.p
          fontSize="xs"
          color="grey.gunsmoke"
          paddingRight="16"
          wordBreak="break-all"
        >
          Odin platform application version {webVersion} / {apiVersion}
        </styled.p>
      </VStack>
    </styled.footer>
  );
}
