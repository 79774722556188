import { usePlatformStatus } from "services/ping/hooks";

export function useFooter() {
  const { apiVersion, webVersion } = usePlatformStatus();

  return {
    apiVersion,
    webVersion,
  };
}
