import React, { PropsWithChildren, useContext } from "react";

import { DialogContext } from "../context";

export function BaseTrigger({ children }: PropsWithChildren) {
  const context = useContext(DialogContext);

  return React.cloneElement(children as React.ReactElement, {
    onClick: context.onOpen,
  });
}
