import { css, cx } from "@@panda/css";
import { Box, VStack, styled } from "@@panda/jsx";
import React, { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { formLabelStyles } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";

export interface CheckboxOption {
  value: string;
  label: string;
}

interface Props {
  name: string;
  label: string;
  subLabel?: string | React.ReactNode;
  options: CheckboxOption[];
  required?: boolean;
  errorMessage?: string | ReactNode;
}

export function FormCheckboxGroup({
  name,
  label,
  subLabel = null,
  options,
  errorMessage,
}: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const withError = Boolean(errors[name]);

  return (
    <styled.fieldset m="0" w="full" data-testid={`form-checkbox-group-${name}`}>
      <styled.legend
        className={cx(formLabelStyles, css({ mb: subLabel ? "0" : "m" }))}
      >
        {label}
      </styled.legend>
      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}
      <Box>
        <VStack alignItems="flex-start" mb="s" _last={{ mb: "0" }}>
          {options.map((opt) => (
            <styled.label
              htmlFor={`${opt.value}_checkbox`}
              display="flex"
              cursor="pointer"
              flexDirection="row"
              alignItems="center"
              userSelect="none"
              my="xs"
              gap="0.4rem"
              key={opt.value}
            >
              <input
                type="checkbox"
                value={opt.value}
                aria-errormessage={`${name}-error`}
                key={opt.value}
                id={`${opt.value}_checkbox`}
                data-testid={`checkbox-${opt.value}`}
                className={css({ cursor: "pointer" })}
                {...register(name)}
                {...(withError ? { "aria-invalid": "true" } : null)}
              />
              {opt.label}
            </styled.label>
          ))}
        </VStack>
        {withError ? (
          <FormError>
            {errorMessage || getFormFieldError(errors[name])}
          </FormError>
        ) : null}
      </Box>
    </styled.fieldset>
  );
}
