import { CSSProperties, createContext } from "react";

export interface DialogContextShape {
  title: string;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  isForceOpen: boolean;
  isLargeScreen: boolean;
  styling: CSSProperties | undefined;
}

export const DialogContext = createContext<DialogContextShape>({
  title: "",
  isOpen: false,
  isForceOpen: false,
  isLargeScreen: true,
  onOpen: () => undefined,
  onClose: () => undefined,
  styling: {},
});
