import { Box } from "@@panda/jsx";
import { inputRecipe } from "components/forms/Form/FormTextField/styles";
import { CSSProperties, ReactNode } from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";
import { Controller, useFormContext } from "react-hook-form";

import { FieldSymbol } from "../FieldSymbol/FieldSymbol";
import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";
import { useFormAmountField } from "./useFormAmountField";

interface Props extends Omit<CurrencyInputProps, "prefix" | "suffix"> {
  name: string;
  label: string | React.ReactNode;
  subLabel?: string | React.ReactNode;
  required?: boolean;
  rightSymbol?: string | undefined;
  leftSymbol?: string | undefined;
  validate?: (val: string | undefined) => boolean;
  triggerValidation?: string[] | string | true;
  invalidMessage?: string;
  customLabel?: boolean | ReactNode;
  testId?: string;
  inputContainerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  initialValue?: string;
}

export function FormAmountField(props: Props) {
  const {
    name,
    label,
    subLabel = null,
    validate = () => true,
    invalidMessage,
    rightSymbol,
    leftSymbol,
    initialValue = "",
    testId = "form-amount-field",
    inputStyle = {},
    inputContainerStyle,
    placeholder = "",
    triggerValidation,
    ...rest
  } = props;

  const {
    formState: { isSubmitted, errors },
    setValue,
    control,
    trigger,
  } = useFormContext();

  useFormAmountField({ setValue, initialValue, fieldName: name });

  const styles = inputRecipe();
  const withError = Boolean(errors[name]);

  const paddingAdjust = leftSymbol
    ? { paddingLeft: leftSymbol.length > 1 ? "90px" : "55px" }
    : rightSymbol
      ? { paddingRight: "55px", paddingLeft: "14px" }
      : null;

  return (
    <Box position="relative" w="full" data-testid={testId}>
      {typeof label === "string" ? (
        <FormLabel htmlFor={name} withSubLabel={Boolean(subLabel)}>
          {label}
        </FormLabel>
      ) : (
        label
      )}
      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}

      <Box position="relative" style={inputContainerStyle}>
        {leftSymbol ? (
          <FieldSymbol placement="left">{leftSymbol}</FieldSymbol>
        ) : null}

        <Controller
          name={name}
          control={control}
          rules={{ validate }}
          render={({ field }) => {
            return (
              <CurrencyInput
                onValueChange={async (val) => {
                  field.onChange(val ?? "");
                  // if defaultValue is set via RHF, onChange doesn't fire if its given undefined,
                  // I think it's because undefined sets the component to an uncontrolled state, so it doesn't do it

                  if (triggerValidation && isSubmitted) {
                    await trigger(
                      triggerValidation === true ? undefined : triggerValidation
                    );
                  }
                }}
                value={field.value}
                onBlur={field.onBlur}
                name={field.name}
                ref={field.ref}
                className={styles}
                style={{ ...inputStyle, ...paddingAdjust }}
                id={name}
                autoComplete="off"
                decimalSeparator="."
                groupSeparator=","
                defaultValue={initialValue}
                aria-label={typeof label === "string" ? label : name}
                aria-errormessage={`${name}-error`}
                decimalsLimit={2}
                placeholder={placeholder}
                {...(withError ? { "aria-invalid": "true" } : null)}
                {...rest}
              />
            );
          }}
        />
        {rightSymbol ? (
          <FieldSymbol placement="right">{rightSymbol}</FieldSymbol>
        ) : null}
      </Box>
      {withError ? (
        <FormError id={`${name}-error`}>
          {invalidMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
