import { z } from "zod";

export const zBranded = {
  "YYYY-MM-DD": (msg?: string) =>
    z
      .string()
      .regex(/^\d{4}-\d{2}-\d{2}$/, msg ?? "Please enter a date")
      .brand("YYYY-MM-DD"),
};

export type Branded = CreateBrandedTypes<typeof zBranded>;

type CreateBrandedTypes<zCustom> =
  zCustom extends Record<string, () => infer U>
    ? {
        //@ts-expect-error works, but not sure how to constrain U
        [K in keyof zCustom]: z.infer<U>;
      }
    : never;
