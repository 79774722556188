/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export * from "./aPIError";
export * from "./aPIErrorMetadata";
export * from "./account";
export * from "./accountAppropriatenessAssessment";
export * from "./accountAppropriatenessAssessmentAnswer";
export * from "./accountAppropriatenessAssessmentAppropriatenessAssessmentStatus";
export * from "./accountAppropriatenessAssessmentInitialProps";
export * from "./accountAppropriatenessAssessmentQuestion";
export * from "./accountAppropriatenessAssessmentResult";
export * from "./accountAppropriatenessAssessmentResultAppropriatenessAssessmentStatus";
export * from "./accountAppropriatenessAssessmentSubmittedAnswer";
export * from "./accountCommonProps";
export * from "./accountDisplayName";
export * from "./accountFilterListParameter";
export * from "./accountFilterParameter";
export * from "./accountForgotPasswordBody";
export * from "./accountForgotPasswordProps";
export * from "./accountGetAppropriatenessAssessmentResponse";
export * from "./accountGetSuccessResponse";
export * from "./accountHandle";
export * from "./accountLegacyAuthToken";
export * from "./accountLoginBody";
export * from "./accountLoginProps";
export * from "./accountLoginSuccessResponse";
export * from "./accountMetadata";
export * from "./accountMutableProps";
export * from "./accountResetPasswordBody";
export * from "./accountResetPasswordProps";
export * from "./accountSignupBody";
export * from "./accountSignupParams";
export * from "./accountSignupParamsSignupIntention";
export * from "./accountSignupProps";
export * from "./accountSignupSuccessResponse";
export * from "./accountSophisticatedInvestorDeclarationProps";
export * from "./accountStatus";
export * from "./accountStatusAssessmentRequired";
export * from "./accountStatusCategorisationRequired";
export * from "./accountStatusPasswordRequired";
export * from "./accountStatusPersonalDetailsRequired";
export * from "./accountStatusReady";
export * from "./accountStatusSophisticatedInvestorDeclarationRequired";
export * from "./accountSubmitAppropriatenessAssessmentBodyBody";
export * from "./accountSubmitAppropriatenessAssessmentResponse";
export * from "./accountUpdateBody";
export * from "./accountsClientCategorisationResultSubmitBody";
export * from "./accountsGetImageSuccessResponse";
export * from "./accountsImpersonateParams";
export * from "./accountsSophisticatedInvestorDeclarationBodyBody";
export * from "./accountsUploadImageBody";
export * from "./additionalPlatformFeeAmount";
export * from "./additionalPlatformFeeItem";
export * from "./additionalPlatformFeeList";
export * from "./address";
export * from "./adminDealsClosedInvestor";
export * from "./adminDealsClosedInvestorsList";
export * from "./adminDealsClosedInvestorsListSuccessResponse";
export * from "./adminDealsClosingDeal";
export * from "./adminDealsClosingGetSuccessResponse";
export * from "./adminDealsClosingInvestor";
export * from "./adminDealsClosingListParams";
export * from "./adminDealsClosingStatusUpdate";
export * from "./adminDealsClosingStatusUpdateBodyBody";
export * from "./adminDealsClosingUpdate";
export * from "./adminDealsClosingUpdateBodyBody";
export * from "./adminDealsFinaliseInvestments";
export * from "./adminDealsFinaliseInvestmentsBodyBody";
export * from "./adminDealsGenerateDealSheetsParams";
export * from "./adminDealsGenerateProofOfInvestmentsParams";
export * from "./adminDealsList";
export * from "./adminDealsListItem";
export * from "./adminDealsListParams";
export * from "./adminDealsListSuccessResponse";
export * from "./adminDealsPublish";
export * from "./adminDealsPublishBodyBody";
export * from "./asset";
export * from "./assetGetOKResponse";
export * from "./assetID";
export * from "./assetURL";
export * from "./assetUploadBody";
export * from "./assetUploadOKResponse";
export * from "./authOAuthProviderCallbackParams";
export * from "./authProvider";
export * from "./authProviderListBody";
export * from "./authProviderListParams";
export * from "./authProviderListResponse";
export * from "./authRedirectPathParameter";
export * from "./authSuccessBody";
export * from "./authSuccessResponse";
export * from "./badRequestResponse";
export * from "./baseCurrency";
export * from "./carriedInterest";
export * from "./clientCategorisation";
export * from "./clientCategorisationAdditionalAnswerType";
export * from "./clientCategorisationCategory";
export * from "./closedAt";
export * from "./closingDealStatus";
export * from "./closingSubmittedAt";
export * from "./commitment";
export * from "./communitiesCreateBody";
export * from "./communitiesCreateSuccessResponse";
export * from "./communitiesGetSuccessResponse";
export * from "./communitiesListSuccessResponse";
export * from "./communitiesMembersListParams";
export * from "./communitiesMembersListSuccessResponse";
export * from "./communitiesMembersListSuccessResponseAllOf";
export * from "./communitiesUpdateBody";
export * from "./community";
export * from "./communityAdminProps";
export * from "./communityAllOf";
export * from "./communityCommonProps";
export * from "./communityHandle";
export * from "./communityInitialProps";
export * from "./communityInviteCode";
export * from "./communityList";
export * from "./communityMetadata";
export * from "./communityMutableProps";
export * from "./communityRelationshipType";
export * from "./companiesCreateBody";
export * from "./companiesCreateSuccessResponse";
export * from "./companiesListSuccessResponse";
export * from "./company";
export * from "./companyCommonProps";
export * from "./companyHandle";
export * from "./companyInitialProps";
export * from "./companyList";
export * from "./companyMetadata";
export * from "./companyPortfolioItemBodyBody";
export * from "./companyPortfolioItemEditableBodyBody";
export * from "./companyPortfolioItemEditableProps";
export * from "./companyPortfolioItemProps";
export * from "./conflictResponse";
export * from "./country";
export * from "./createdAt";
export * from "./currentNetInvestment";
export * from "./currentStandardPlatformFee";
export * from "./currentSyndicateLeadFee";
export * from "./customFeeAmount";
export * from "./deal";
export * from "./dealAdminProps";
export * from "./dealCommonProps";
export * from "./dealCreator";
export * from "./dealDraftCreateBody";
export * from "./dealDraftFees";
export * from "./dealInfoUpdateRequest";
export * from "./dealInfoUpdateRequestDeckUrlEmbed";
export * from "./dealInternalDealStatusFilterParameter";
export * from "./dealInternalDealStatusList";
export * from "./dealInvestor";
export * from "./dealInvestorInvestmentStatus";
export * from "./dealInvite";
export * from "./dealMaterials";
export * from "./dealMessageBodyText";
export * from "./dealMessageDirection";
export * from "./dealMessageInitialProps";
export * from "./dealMessageInitialRecipientList";
export * from "./dealMessageProps";
export * from "./dealMessageRecipient";
export * from "./dealMessageRecipientList";
export * from "./dealStatus";
export * from "./dealStatusFilterParameter";
export * from "./dealStatusList";
export * from "./dealTypeEquityInfo";
export * from "./dealTypeInfo";
export * from "./dealTypeNonEquityInfo";
export * from "./dealsEventsClose";
export * from "./dealsEventsPublishBody";
export * from "./dealsEventsPublishRequest";
export * from "./dealsEventsType";
export * from "./dealsGetInfoSuccessResponse";
export * from "./dealsGetLogoSuccessResponse";
export * from "./dealsGetPublicInfoSuccessBody";
export * from "./dealsGetPublicInfoSuccessResponse";
export * from "./dealsMessagesCreateBody";
export * from "./dealsMessagesCreateSuccessResponse";
export * from "./dealsUpdateDraftArchiveStatusBody";
export * from "./dealsUpdateDraftGetLogoResponse";
export * from "./dealsUpdateDraftReviewStatusBody";
export * from "./dealsUpdateDraftUploadLogoBody";
export * from "./dealsUpdateInfoBody";
export * from "./dealsUploadLogoBody";
export * from "./directAccessErrorResponse";
export * from "./draft";
export * from "./draftArchiveStatus";
export * from "./draftCompany";
export * from "./draftCreateOkResponse";
export * from "./draftEstimatedFees";
export * from "./draftGetOkResponse";
export * from "./draftImmutableProps";
export * from "./draftInitialProps";
export * from "./draftMaterials";
export * from "./draftMeta";
export * from "./draftMovedResponse";
export * from "./draftMovedSlugs";
export * from "./draftMutableProps";
export * from "./draftReviewStatus";
export * from "./draftSecondarySeller";
export * from "./draftStatus";
export * from "./draftSyndicate";
export * from "./draftSyndicateAllOf";
export * from "./draftTerms";
export * from "./draftTermsCompleteState";
export * from "./draftTermsEquityShareClass";
export * from "./draftUpdateBody";
export * from "./dueDiligenceStatus";
export * from "./estimatedPlatformFee";
export * from "./estimatedPlatformFeePercentage";
export * from "./finalAdditionalPlatformFeeAmount";
export * from "./finalInvestment";
export * from "./finalNetInvestment";
export * from "./finalNetInvestmentAmount";
export * from "./finalPlatformFee";
export * from "./finalStandardOrCustomPlatformFee";
export * from "./getVersion200";
export * from "./handle";
export * from "./hydratedCarrySplit";
export * from "./hydratedCarrySplitEntity";
export * from "./identifier";
export * from "./internalDealStatus";
export * from "./internalServerErrorResponse";
export * from "./investment";
export * from "./investmentCommitmentAmountParameter";
export * from "./investmentCommonProps";
export * from "./investmentConfirmPaymentBody";
export * from "./investmentDetails";
export * from "./investmentFees";
export * from "./investmentGetFeesSuccessResponse";
export * from "./investmentGetSuccessResponse";
export * from "./investmentLegalEntity";
export * from "./investmentMaterialsDownloadSuccessResponse";
export * from "./investmentMetadata";
export * from "./investmentMutableProps";
export * from "./investmentPaymentConfirmation";
export * from "./investmentPaymentEmailParameter";
export * from "./investmentPaymentForceParameter";
export * from "./investmentPaymentInitialProps";
export * from "./investmentPaymentSource";
export * from "./investmentRound";
export * from "./investmentStatus";
export * from "./investmentTermsAcceptanceDate";
export * from "./investmentType";
export * from "./investmentUpdateBody";
export * from "./investmentUpdateSuccessResponse";
export * from "./investmentsConfirmPaymentParams";
export * from "./investmentsConfirmPaymentSuccessResponse";
export * from "./investmentsGetFeesParams";
export * from "./investmentsMaterialsDownloadParams";
export * from "./investorEstimatedAdditionalPlatformFeeAmount";
export * from "./investorEstimatedDealFee";
export * from "./investorEstimatedPlatformFee";
export * from "./investorEstimatedStandardPlatformFee";
export * from "./investorEstimatedSyndicateLeadFee";
export * from "./investorFinalAdditionalPlatformFee";
export * from "./investorFinalDealFee";
export * from "./investorFinalPlatformFee";
export * from "./investorFinalRoundDownAdjustment";
export * from "./investorFinalStandardPlatformFee";
export * from "./investorFinalSyndicateLeadFee";
export * from "./inviteGenericAcceptProps";
export * from "./inviteGenericCreateSuccessBody";
export * from "./inviteGenericInitialProps";
export * from "./invitePersonalInitialProps";
export * from "./invitePersonalInitialPropsAllOf";
export * from "./invitesGenericAcceptBody";
export * from "./invitesGenericAcceptSuccessResponse";
export * from "./invitesGenericCreateBody";
export * from "./invitesGenericCreateSuccessResponse";
export * from "./invitesPersonalCreateBody";
export * from "./invitesPersonalCreateSuccessResponse";
export * from "./legacyAccountIDParameter";
export * from "./legalEntitiesCreateBody";
export * from "./legalEntitiesListParams";
export * from "./legalEntity";
export * from "./legalEntityCommonProps";
export * from "./legalEntityInfoBusiness";
export * from "./legalEntityInfoIndividual";
export * from "./legalEntityInitialProps";
export * from "./legalEntityList";
export * from "./legalEntityListSuccessResponse";
export * from "./legalEntityMetadata";
export * from "./legalEntityRelationshipFilterParameter";
export * from "./legalEntitySuccessResponse";
export * from "./legalEntityType";
export * from "./legalEntityTypeUnion";
export * from "./monetaryValue";
export * from "./notAuthorisedResponse";
export * from "./notFoundResponse";
export * from "./numericValue";
export * from "./oAuthCodeParameter";
export * from "./oAuthStateParameter";
export * from "./paginationPageParamParameter";
export * from "./paginationProps";
export * from "./paymentStatus";
export * from "./platformFeeType";
export * from "./pricePlan";
export * from "./pricePlanBasePlusVariable";
export * from "./pricePlanBasePlusVariableItem";
export * from "./pricePlanBasePlusVariableVariant";
export * from "./pricePlanDisplayName";
export * from "./pricePlanFloorCap";
export * from "./pricePlanFloorCapItem";
export * from "./pricePlanFloorCapVariant";
export * from "./pricePlanName";
export * from "./pricePlanVariant";
export * from "./pricePlanVariantProps";
export * from "./pricePlansList";
export * from "./pricePlansListSuccessResponse";
export * from "./profile";
export * from "./profileCommonProps";
export * from "./profilesGetSuccessResponse";
export * from "./proxyVotingTarget";
export * from "./publicProfilePortfolio";
export * from "./publicProfilePortfolioItem";
export * from "./publicProfilePortfolioItemOrigin";
export * from "./publicProfilePortfolioItemProps";
export * from "./publicProfilePortfolioItemVisibility";
export * from "./roundDownAdjustment";
export * from "./subscription";
export * from "./subscriptionAmount";
export * from "./subscriptionAmountItem";
export * from "./subscriptionCadence";
export * from "./subscriptionDisplayName";
export * from "./subscriptionTier";
export * from "./subscriptionTiersList";
export * from "./subscriptionTiersListSuccessResponse";
export * from "./syndicate";
export * from "./syndicateCommonProps";
export * from "./syndicateFeeType";
export * from "./syndicateInitialProps";
export * from "./syndicateLeadReference";
export * from "./syndicateLeadReferenceList";
export * from "./syndicateLeadsMutability";
export * from "./syndicateLegalName";
export * from "./syndicateLegalNameMutability";
export * from "./syndicateList";
export * from "./syndicateListItem";
export * from "./syndicateListItemAllOf";
export * from "./syndicateMember";
export * from "./syndicateMemberList";
export * from "./syndicateMembershipType";
export * from "./syndicateMetadata";
export * from "./syndicateMutableProps";
export * from "./syndicateName";
export * from "./syndicateRelationshipFilterParameter";
export * from "./syndicateSlug";
export * from "./syndicatesCreateBody";
export * from "./syndicatesGetSuccessResponse";
export * from "./syndicatesListParams";
export * from "./syndicatesListSuccessResponse";
export * from "./syndicatesUpdateBody";
export * from "./targetClosingAt";
export * from "./totalCommitted";
export * from "./totalPaid";
export * from "./totalReceived";
export * from "./totalRequested";
export * from "./transactionType";
export * from "./transfersReceiveWiseBody";
export * from "./unident";
export * from "./updatedAt";
export * from "./url";
export * from "./valuationCapType";
