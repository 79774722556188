import { PropsWithChildren, useContext } from "react";

import { DialogContext } from "../context";
import { DialogWrap } from "./DialogWrap";
import { DrawerWrap } from "./DrawerWrap";

type Props = PropsWithChildren & {
  testId?: string;
};

export function BaseContent({ children, testId = "modal-content" }: Props) {
  const context = useContext(DialogContext);

  return context.isLargeScreen ? (
    <DialogWrap testId={testId} {...context}>
      {children}
    </DialogWrap>
  ) : (
    <DrawerWrap testId={testId} {...context}>
      {children}
    </DrawerWrap>
  );
}
