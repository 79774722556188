import { Branded, zBranded } from "utility/brandedTypes";

export const formatIsoDate = (
  isoDateString: string | undefined | null,
  opts?: Intl.DateTimeFormatOptions
): string => {
  if (!isoDateString) return "";
  if (!isDateValid(new Date(isoDateString))) return "";

  return new Date(isoDateString).toLocaleString(undefined, {
    dateStyle: "medium",
    ...opts,
  });
};

function isDateValid(date: unknown): boolean {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

export const isoDateStringToYYYYMMDD = <TFallBack>(
  isoDateString: string | undefined | null,
  options: { fallback: TFallBack }
): Branded["YYYY-MM-DD"] | TFallBack => {
  if (!isoDateString) return options.fallback;

  const dateObj = new Date(isoDateString);
  if (isNaN(dateObj.getTime())) return options.fallback;

  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const string = `${year}-${month}-${day}`;

  const parsed = zBranded["YYYY-MM-DD"]().safeParse(string);
  return parsed.success ? parsed.data : options.fallback;
};

export function getMaxDateToTwoYearsFromNow() {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2);
  return isoDateStringToYYYYMMDD(date.toISOString(), { fallback: "" });
}
