import { useState } from "react";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

type Args = {
  controlled:
    | {
        isOpen: boolean;
        set: (open: boolean) => void;
      }
    | undefined;
};

export function useDialogInternal({ controlled }: Args) {
  const [isOpen, setOpen] = useState(false);

  const { isLgScreenUp } = useBreakpoint("lgUp");

  const [isLgScreenWhenFirstOpened, setIsLgScreenWhenFirstOpened] =
    useState(isLgScreenUp);

  const handleOpen = (isOpen: boolean) => {
    setIsLgScreenWhenFirstOpened(isLgScreenUp);
    setOpen(isOpen);
  };

  function handleDialogOpenChange(open: boolean) {
    if (controlled) {
      controlled.set(open);
    } else {
      handleOpen(open);
    }
  }

  return {
    isOpen: controlled ? controlled.isOpen : isOpen,
    onOpen: () => handleOpen(true),
    onClose: () => setOpen(false),
    isLgScreenUp: isLgScreenWhenFirstOpened,
    handleDialogOpenChange,
  };
}
