import { css } from "@@panda/css";
import { Flex, Stack } from "@@panda/jsx";
import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { OdinLogo } from "components/main/OdinLogo/OdinLogo";
import NextLink from "next/link";

import { AccountMenu } from "../AccountMenu/AccountMenu";
import { CommListIcon } from "../CommListIcon/CommListIcon";
import { NavButton } from "../NavButton/NavButton";
import { GetHelpButton } from "./components/GetHelpButton";
import { useLoggedInView } from "./useLoggedInView";

export const NAV_SIZE = "4rem";
export const NAV_BTN_SIZE = ["1.875rem", "1.875rem", "1.875rem", "2rem"];
const NARROW_SCREEN_MAX_WIDTH = "32rem";

export function LoggedInView() {
  const { isLgScreenUp, isCommunityPage, isOnboarding, isDeals } =
    useLoggedInView();

  return (
    <Flex
      maxWidth={NARROW_SCREEN_MAX_WIDTH}
      px="6"
      flexDirection={isLgScreenUp ? "column" : "row"}
      mx={isLgScreenUp ? "unset" : "auto"}
      width="full"
      justifyContent={isLgScreenUp ? "space-between" : "center"}
      alignItems="center"
      height="full"
      py="4"
    >
      <Stack
        width="full"
        direction={isLgScreenUp ? "column" : "row"}
        alignItems="center"
        justifyContent={isLgScreenUp ? "center" : "space-between"}
        gap={isLgScreenUp ? "8" : "2"}
      >
        <NavButton label="Odin logo" variant="static">
          <OdinLogo />
        </NavButton>
        {!isOnboarding ? (
          <>
            <NavButton
              variant="link"
              testId="nav-button-deals"
              isActive={isDeals}
              label="Deals"
            >
              <NextLink
                aria-label="Deals"
                href="/deals"
                className={css({ padding: "0.45rem!" })}
              >
                <BriefcaseIcon width="1rem" color="white" />
              </NextLink>
            </NavButton>
            <NavButton
              variant="link"
              label="Syndicates"
              isActive={isCommunityPage}
              testId="nav-button-communities"
            >
              <NextLink
                aria-label="Syndicates"
                href="/syndicates"
                className={css({ padding: "0.45rem!" })}
              >
                <CommListIcon />
              </NextLink>
            </NavButton>
          </>
        ) : null}
        <GetHelpButton />
        {!isLgScreenUp ? <AccountMenu /> : null}
      </Stack>
      {isLgScreenUp ? <AccountMenu /> : null}
    </Flex>
  );
}
