import { css } from "@@panda/css";
import { BoxProps } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import type { LinkProps } from "next/link";
import NextLink from "next/link";
import { CSSProperties, PropsWithChildren } from "react";

type Variant = "primary" | "secondary" | "tertiary";

interface Props extends LinkProps, PropsWithChildren {
  variant?: Variant;
  isExternal?: boolean;
  wide?: boolean;
  testId?: string;
  whiteSpace?: BoxProps["whiteSpace"];
  style?: CSSProperties;
}

const baseStyles = css({
  textDecoration: "none",
});

const wideStyles = css({
  display: "block",
  w: "100%",
});

const variantColorMap: { [v in Variant]: string } = {
  primary: "brand.krilin",
  secondary: "grey.iron",
  tertiary: "white",
};

export function Link({
  children,
  variant = "primary",
  isExternal = false,
  wide = false,
  href,
  testId,
  whiteSpace = "unset",
  ...rest
}: Props) {
  const color = variantColorMap[variant];

  const content = (
    <Typography
      variant="body"
      borderBottom={variant === "secondary" ? "1px solid" : "none"}
      color={color}
      cursor="pointer"
      as="span"
      fontSize="inherit"
      fontWeight={variant === "secondary" ? "400" : "600"}
      data-testid={isExternal ? undefined : testId}
      textDecoration="none"
      whiteSpace={whiteSpace}
      _hover={{
        color: variant === "secondary" ? "grey.alabaster" : "brand.yamcha",
      }}
    >
      {children}
    </Typography>
  );

  return isExternal ? (
    <a
      href={href as string}
      rel="noreferrer"
      target="_blank"
      className={`${baseStyles} ${wide ? wideStyles : ""}`}
      data-testid={testId}
      {...rest}
    >
      {content}
    </a>
  ) : (
    <NextLink
      href={href}
      passHref
      className={`${baseStyles} ${wide ? wideStyles : ""}`}
      {...rest}
    >
      {content}
    </NextLink>
  );
}
