import { css } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { Spinner } from "components/main/Spinner/Spinner";

interface Props {
  cover?: boolean;
}

export function LoadingBanner({ cover = false }: Props) {
  const coverProps = cover
    ? {
        position: "fixed" as const,
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "floating",
      }
    : {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minH: "200px",
      };

  return (
    <Box {...coverProps} data-testid="loading-banner">
      <Spinner
        color={token("colors.brand.krilin")}
        strokeWidth="8"
        className={css({
          w: "2rem",
          h: "2rem",
          position: "relative",
        })}
      />
    </Box>
  );
}
