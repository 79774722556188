import { css, cx } from "@@panda/css";
import { Box, HStack, VisuallyHidden } from "@@panda/jsx";
import { XMarkIcon } from "@heroicons/react/24/outline";
import * as RXDialog from "@radix-ui/react-dialog";
import { CSSProperties, PropsWithChildren } from "react";

import { DialogContextShape } from "../context";
import { modalStyles } from "./styles";

type BaseProps = DialogContextShape & PropsWithChildren;

interface Props extends BaseProps {
  testId?: string;
  title: string;
  styling: CSSProperties | undefined;
}

export function DialogWrap({
  children,
  title,
  testId,
  styling = {},
  isForceOpen,
  onClose,
}: Props) {
  return (
    <RXDialog.Portal>
      <RXDialog.Overlay className={modalStyles.overlay} />
      <RXDialog.Content
        className={cx(modalStyles.content)}
        style={{
          maxWidth: "40rem",
          ...styling,
          boxShadow: modalStyles.contentShadow,
        }}
        data-testid={testId || "modal-content"}
      >
        {/* Description is required for a11y compliance */}
        <VisuallyHidden>
          <RXDialog.Description>Dialog description</RXDialog.Description>
        </VisuallyHidden>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          pb={title ? "xl" : "0"}
        >
          {title ? (
            <RXDialog.Title className={modalStyles.title}>
              {title}
            </RXDialog.Title>
          ) : (
            <VisuallyHidden>
              {/* Title is required for a11y compliance even if not displayed so screen readers can announce it */}
              <RXDialog.Title>{title}</RXDialog.Title>
            </VisuallyHidden>
          )}

          {isForceOpen && !onClose ? null : (
            <RXDialog.Close
              aria-label="Close"
              className={cx(
                modalStyles.iconWrap,
                css({
                  transition: ".2s background ease",
                  _hover: {
                    bgColor: "grey.minecraft",
                  },
                })
              )}
            >
              <XMarkIcon className={modalStyles.icon} color={"white"} />
            </RXDialog.Close>
          )}
        </HStack>
        <Box overflowY="auto">{children}</Box>
      </RXDialog.Content>
    </RXDialog.Portal>
  );
}
