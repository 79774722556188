import * as RXDialog from "@radix-ui/react-dialog";
import { CSSProperties, PropsWithChildren } from "react";
import { Drawer } from "vaul";

import { BaseContent } from "./components/BaseContent";
import { BaseFooter } from "./components/BaseFooter";
import { BaseTrigger } from "./components/BaseTrigger";
import { DialogContext } from "./context";
import { useDialogInternal } from "./useDialog";
import { useDialog } from "./utils";

interface Props extends PropsWithChildren {
  title?: string;
  styling?: CSSProperties;
  forceOpen?: boolean;
  onClose?: (() => void) | undefined;
  controlled?: {
    isOpen: boolean;
    set: (open: boolean) => void;
  };
}

Dialog.Trigger = BaseTrigger;
Dialog.Content = BaseContent;
Dialog.Footer = BaseFooter;

function Dialog({
  children,
  title,
  styling,
  forceOpen = false,
  onClose,
  controlled,
}: Props) {
  const config = useDialogInternal({ controlled });

  const onOpenChange = (e: boolean) => {
    if (e === false) onClose?.();
    config.handleDialogOpenChange(e);
  };

  return (
    <DialogContext.Provider
      value={{
        title: title || "",
        isOpen: forceOpen || config.isOpen,
        isForceOpen: forceOpen,
        isLargeScreen: config.isLgScreenUp,
        onOpen: config.onOpen,
        onClose: () => {
          config.onClose();
          onClose?.();
        },
        styling,
      }}
    >
      {config.isLgScreenUp ? (
        <RXDialog.Root
          open={forceOpen || config.isOpen}
          onOpenChange={onOpenChange}
        >
          {children}
        </RXDialog.Root>
      ) : (
        <Drawer.Root
          shouldScaleBackground
          open={forceOpen || config.isOpen}
          onOpenChange={onOpenChange}
        >
          {children}
        </Drawer.Root>
      )}
    </DialogContext.Provider>
  );
}

export { Dialog, useDialog };
