export type MergeProps<TBase, UCustom> = Omit<TBase, keyof UCustom> & UCustom;

export function exhaustiveCheck<T = unknown>(
  x: never,
  options?: { fallback: T }
) {
  return options?.fallback ?? x;
}

export type NonNullableValues<T> = {
  [Key in keyof T]: NonNullable<T[Key]>;
};

export type GetFormLabels<T> = T extends (arg: never) => { label: infer U }
  ? U
  : never;

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;
