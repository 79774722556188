export const theme = {
  fontSizes: {
    xs: { value: "0.813rem" }, // 13px
    sm: { value: "0.875rem" }, // 14px
    md: { value: "1rem" }, // 16px
    ld: { value: "1.125rem" }, // 18px
    lg: { value: "1.25rem" }, // 20px
    xl: { value: "1.5rem" }, // 24px
    "2xl": { value: "2rem" }, // 32px
  },
  fonts: {
    heading: { value: "'Muoto', Arial, sans-serif" },
    body: { value: "'Muoto', Arial, sans-serif" },
    mono: {
      value:
        "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace",
    },
  },
  lineHeights: {
    normal: { value: "normal" },
    none: { value: 1 },
    shorter: { value: 1.25 },
    short: { value: 1.375 },
    base: { value: 1.5 },
    tall: { value: 1.625 },
    taller: { value: "2" },
    "3": { value: ".75rem" }, // 12px
    "4": { value: "1rem" }, // 16px
    "5": { value: "1.25rem" }, // 20px
    "6": { value: "1.5rem" }, // 24p
    "7": { value: "1.75rem" }, // 28px
    "8": { value: "2rem" }, // 32px
    "9": { value: "2.25rem" }, // 36px
    "10": { value: "2.5rem" }, // 40px
  },
  fontWeights: {
    hairline: { value: 100 },
    thin: { value: 200 },
    light: { value: 300 },
    normal: { value: 400 },
    medium: { value: 500 },
    semibold: { value: 600 },
    bold: { value: 700 },
    extrabold: { value: 800 },
    black: { value: 900 },
  },
  colors: {
    transparent: { value: "transparent" },
    current: { value: "currentColor" },
    black: { value: "#000000" },
    white: { value: "#FFFFFF" },
    whitewash: { value: "rgba(256, 256, 256, 0.1)" },
    grey: {
      bunker: { value: "#161616" },
      woodsmoke: { value: "#1E1E1E" },
      minecraft: { value: "#282828" },
      dune: { value: "#383838" },
      gunsmoke: { value: "#838383" },
      iron: { value: "#D1D5DB" },
      alabaster: { value: "#F9FAFB" },
    },
    brand: {
      gohan: { value: "#4719A2" },
      frieza: { value: "#643EBA" },
      haze: { value: "#8151EF" },
      fauve: { value: "#9477F3" },
      chalk: { value: "#ECE9FD" },
      krilin: { value: "#EAB189" },
      yamcha: { value: "#D7905E" },
    },
    purple: {
      gohan: { value: "#4719A2" },
      frieza: { value: "#643EBA" },
      haze: { value: "#8151EF" },
      fauve: { value: "#9477F3" },
      chalk: { value: "#ECE9FD" },
    },
    green: {
      forest: { value: "#236241" },
      moss: { value: "#47B47B" },
      piccolo: { value: "#47B47B" },
      swamp: { value: "#EEFEF4" },
    },
    red: {
      jiren: { value: "#A91919" },
      firebrick: { value: "#B71C1C" },
      salmon: { value: "#F98B85" },
      mist: { value: "#FDE6E6" },
    },
    yellow: {
      vesuvius: { value: "#B45309" },
      yellowsea: { value: "#F7A500" },
      coral: { value: "#F6814F" },
      cream: { value: "#FFFBEB" },
    },
    blue: {
      new: { value: "#77C6F3" },
    },
    amber: {
      vesuvius: { value: "#B45309" },
      yellowsea: { value: "#F7A500" },
      cream: { value: "#FFFBEB" },
    },
  },
  radii: {
    none: { value: "0" },
    sm: { value: "0.125rem" }, // 2px
    base: { value: "0.25rem" }, // 4px
    md: { value: "0.375rem" }, // 6px
    lg: { value: "0.5rem" }, // 8px
    xl: { value: "0.75rem" }, // 12px
    "2xl": { value: "1rem" }, // 16px
    "3xl": { value: "1.5rem" }, // 24px
    full: { value: "9999px" },
  },
  spacing: {
    "1px": { value: "1px" },
    0.5: { value: "0.125rem" }, // 2px
    xs: { value: "0.25rem" }, // 4px
    s: { value: "0.5rem" }, // 8px
    m: { value: "1rem" }, // 16px
    l: { value: "1.5rem" }, //24 px
    xl: { value: "2rem" },
    "2xl": { value: "2.5rem" },
    "3xl": { value: "3rem" },
    // Chakra scale (legacy, try to not use...)
    1: { value: "0.25rem" }, // 4px
    1.5: { value: "0.375rem" }, // 6px
    2: { value: "0.5rem" }, // 8px
    2.5: { value: "0.625rem" }, // 10px
    3: { value: "0.75rem" }, // 12px
    3.5: { value: "0.875rem" }, // 14px
    4: { value: "1rem" }, // 16px
    5: { value: "1.25rem" }, // 20px
    6: { value: "1.5rem" }, // 24px
    7: { value: "1.75rem" }, // 28px
    8: { value: "2rem" }, // 32px
    9: { value: "2.25rem" }, // 36px
    10: { value: "2.5rem" }, // 40px
    12: { value: "3rem" }, // 48px
    14: { value: "3.5rem" }, // 56px
    16: { value: "4rem" }, // 64px
    20: { value: "5rem" }, // 80px
    24: { value: "6rem" }, // 96px
    28: { value: "7rem" }, // 112px
    32: { value: "8rem" }, // 128px
    36: { value: "9rem" }, // 144px
    40: { value: "10rem" }, // 160px
    44: { value: "11rem" }, // 176px
    48: { value: "12rem" }, // 192px
    52: { value: "13rem" }, // 208px
    56: { value: "14rem" }, // 224px
    60: { value: "15rem" }, // 240px
    64: { value: "16rem" }, // 256px
    72: { value: "18rem" }, // 288px
    80: { value: "20rem" }, // 320px
    96: { value: "24rem" }, // 384px
  },
  breakpoints: {
    base: "0px",
    sm: "480px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
  zIndex: {
    banner: { value: 5000 },
    callout: { value: 600 },
    nav: { value: 900 },
    floating: { value: 2000 },
    tooltip: { value: 7000 },
    modal: { value: 8000 },
  },
} as const;
