import { css, cx } from "@@panda/css";
import { Tooltip } from "components/main/Tooltip";
import { HTMLAttributes } from "react";

import { NAV_BTN_SIZE } from "../LoggedInView/LoggedInView";

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string;
  isActive?: boolean;
  testId?: string;
  variant: "link" | "button" | "static";
}

export function NavButton({
  label,
  children,
  isActive = false,
  className,
  variant,
  testId,
  ...rest
}: Props) {
  const content = (
    <div
      data-testid={testId}
      aria-selected={isActive}
      aria-label={label}
      role={variant === "button" ? "button" : "unset"}
      className={cx(
        css({
          display: "flex",
          flexDir: "row",
          alignItems: "center",
          justifyContent: "center",
          borderWidth: "0.5px",
          borderColor: "grey.dune",
          borderStyle: "solid",
          borderRadius: "lg",
          bgColor: "transparent",
          width: NAV_BTN_SIZE,
          height: NAV_BTN_SIZE,
          flexShrink: "0",
          transition: ".2s background easy-in-out",
          cursor: variant === "static" ? "default" : "pointer",
          p: "0",
          m: "0",
          _hover: {
            bgColor: "grey.minecraft",
          },
          _selected: {
            bgColor: "grey.minecraft",
          },
        }),
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );

  return variant === "static" ? (
    content
  ) : (
    <Tooltip
      variant="secondary"
      size="xs"
      label={label}
      positioning={{ placement: "right" }}
    >
      {content}
    </Tooltip>
  );
}
